import React, { useState } from "react";
import { motion } from "framer-motion";
import h2h from "./data/h2h.json";

type Language = "en" | "th";

interface CardProps {
  info: CardInfo;
  isBack: boolean;
  onClick: () => void;
  backCover: React.ReactNode;
  question: string;
}

const Card: React.FC<CardProps> = ({
  isBack,
  onClick,
  backCover,
  question,
}) => {
  return (
    <div className="relative w-96 h-64" onClick={onClick}>
      <motion.div
        initial={{ rotateY: isBack ? 0 : 180 }}
        animate={{ rotateY: isBack ? 0 : 180 }}
        style={{ backfaceVisibility: "hidden" }}
        transition={{ duration: 0.5 }}
        className="absolute bg-white rounded-lg shadow-lg w-96 h-64 overflow-hidden"
      >
        <div className="flex flex-col justify-between h-full select-none">
          <div className="flex justify-center items-center h-full">
            <div className="text-2xl font-bold">{backCover}</div>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ rotateY: isBack ? 180 : 0 }}
        animate={{ rotateY: isBack ? 180 : 0 }}
        style={{ backfaceVisibility: "hidden" }}
        transition={{ duration: 0.5 }}
        className="absolute bg-white rounded-lg shadow-lg p-4 w-96 h-64 overflow-hidden"
      >
        <div className="flex flex-col justify-between h-full select-none">
          <div className="flex justify-center items-center h-full">
            <div className="text-2xl font-bold">{question}</div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

interface CardInfo {
  question: {
    en?: string;
    th?: string;
  };
}
interface DeckProps {
  cards: CardInfo[];
  backCover: React.ReactNode;
  language: Language;
}

const Deck: React.FC<DeckProps> = ({ cards, backCover, language }) => {
  // const [topCard, setTopCard] = useState(0);
  const defaultCard = 0;
  const [isBack, setIsBack] = useState(true);
  const [card, setCard] = useState(cards[defaultCard]);
  const [question, setQuestion] = useState(
    cards[defaultCard].question[language]
  );
  const [cardIndex, setCardIndex] = useState(defaultCard);

  const handleClick = () => {
    // random new card when clicking on the back side
    if (isBack) {
      const randomCard = Math.floor(Math.random() * cards.length);
      // setCard(cards[randomCard]);
      // setQuestion(cards[randomCard].question[language]);
      setCardIndex(randomCard);
    }

    setIsBack((prev) => !prev);
  };

  return (
    <div>
      <Card
        key={card.question.en}
        info={card}
        isBack={isBack}
        backCover={backCover}
        onClick={handleClick}
        question={cards[cardIndex].question[language] || "N/A"}
      />
    </div>
  );
};

export default function App() {
  const defaultLanguage = "en";

  const [language, setLanguage] = useState<Language>(defaultLanguage);

  return (
    <div className="container mx-auto py-2">
      <div className="text-xl font-bold">{h2h.name}</div>
      <div className="flex flex-row ">
        <div className="pr-2" onClick={() => setLanguage("en")}>
          EN
        </div>{" "}
        |{" "}
        <div className="px-2" onClick={() => setLanguage("th")}>
          TH
        </div>
      </div>

      <div className="flex flex-col">
        {h2h.decks.map((deck, index) => (
          <div className="my-4" key={index}>
            <Deck
              cards={deck.cards}
              backCover={<img src={deck.backCoverUrl} alt={deck.name} />}
              language={language}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
